import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import './UserIcon.css'

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

function UserIcon(props) {
    
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  
    const thumbs = acceptedFiles.map(file => {
        props.seleccionarImagen(file);
        return (
            <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                    <img
                        src={URL.createObjectURL(file)}
                        style={img}
                    />
                </div>
            </div>
        )
    });

    return (
        <section className="container">
            {props.title}
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Arrastre la imagen que desea subir</p>
            </div>
            {!props.notThumbs && thumbs.length > 0 && <aside>
                <h4>Archivo seleccionado:</h4>
                <ul>{thumbs}</ul>
            </aside>}
        </section>
    );
}

export default UserIcon;