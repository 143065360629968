import React, { Component, forwardRef } from 'react';
import './Roles.css';
import axios from 'axios';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/AddCircle';
import Check from '@material-ui/icons/Check';
import FilterList from '@material-ui/icons/FilterList';
import Remove from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MainContext from '../MainContext';
import Constants from '../Constants';
import AgregarRoles from './AgregarRoles/AgregarRoles';
import Alert from '../shared/dialog/alert/Alert';
import Wheel from '../shared/dialog/wheel/Wheel';

class Roles extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      	columns: [
		      { title: 'Nombre', field: 'nombre' }
		    ],
		    RoleData: [],
		    roles: [],
	    	showList: true,
	    	selectedRole: {},
			alertData: {
				title: "Error",
				message: "hubo un error",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			openWheel: false
	    }
	}

	toggleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	openEdit = (role)=> {
		if(!this.context.hasPermission('crear-rol')) {
			let alertData = this.state.alertData;
	    	alertData.message = "No tienes permiso para editar este rol";
	    	this.setState({alertData: alertData});	
          	this.toggleAlert();
		 } else {
	  		this.setState({
	  			selectedRole: role,
	  			showList: !this.state.showList
	  		});
	  	}
  	}

    showList = ()=> {
    	this.reloadRoles();
  		this.setState({showList: true});
  	}

    toggleShowList = ()=> {
  		this.setState({showList: !this.state.showList});
  	}
  	openList = ()=> {
  		this.setState({
  			showList: false,
  			selectedRole: {}
  		});
  	}

  	reloadRoles = () => {
		this.setState({
			openWheel: true
		});
  		axios.get(Constants.api + '/v1/rol')
	    .then((resp)=>{	
		  	this.setState({
				openWheel: false,
		  		RoleData: resp.data
		  	});
	    }).catch(error => {
			this.setState({
				openWheel: false
			});
	    	if (error.message.indexOf('401') >= 0) {
	    		this.context.logOut();
	    	}
        });
  	}

	componentDidMount(){
		this.reloadRoles();
	}

    render() {
        return (
        	<div>        	
	            {this.state.showList && <div className="table-container">
	            	{this.context.hasPermission('crear-rol') && <div className="add-role" onClick={this.openList}>
	            		<h3>Agregar Rol</h3>
	            		<Add />
	            	</div>}
	                <MaterialTable
				      title="Roles"
				      localization={{
				        header: {
				            actions: 'Acciones'
				        },
				        body: {
				            emptyDataSourceMessage: 'No hay datos',				            
					        editRow: {
					          saveTooltip: "Aceptar",
					          cancelTooltip: "Cancelar",
					          deleteText: "Realmente desea eliminar este Rol?"
					        },
					        addTooltip: "Agregar nuevo Rol",
					        deleteTooltip: "Eliminar",
					        editTooltip: "Editar"
					      
				        },
				        toolbar: { searchPlaceholder: 'Buscar' }
				      }}
				      columns={this.state.columns}
				      data={this.state.RoleData}
				      icons={{ 
			            Check: Check,
			            DetailPanel: ChevronRight,
			            Export: SaveAlt,
			            Filter: FilterList,
			            FirstPage: FirstPage,
			            LastPage: LastPage,
			            NextPage: ChevronRight,
			            PreviousPage: ChevronLeft,
			            Search: Search,
			            Delete: forwardRef((props, ref) => <Remove {...props} ref={ref} className="red-icon"/>),
			            Edit: Edit,
			            Add: Add,
			            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} className="red-icon"/>),
			            ResetSearch: Clear,
			            SortArrow: ArrowDownward
			          }}
				      editable={{
				        onRowDelete: (oldData) =>
				          new Promise((resolve) => {
				          	if(this.context.hasPermission('borrar-rol')) {
								let alertData = this.state.alertData;
						    	alertData.message = "No tienes permiso para eliminar este rol";
						    	this.setState({alertData: alertData});		  							  	
					            resolve();
					          	this.toggleAlert();
							} else {
					           	axios.delete(Constants.api + '/v1/rol/'+oldData.pkIdRol)
							    .then((resp)=>{		  							  	
					              resolve();
					              this.reloadRoles();
							    }).catch(error => {
							    	if (error.message.indexOf('401') >= 0) {
							    		this.context.logOut();
							    	}
							    	let alertData = this.state.alertData;
							    	alertData.message = "No se puede eliminar un rol con usuarios asociados";
							    	this.setState({alertData: alertData});					  	
					              	resolve();
					              	this.toggleAlert();
						        });
						    }
				          }),
				      }}
				      actions={[
						  {
						    icon: Edit,
						    tooltip: 'Editar',
						    onClick: (event, rowData) => this.openEdit(rowData)
						  }
						]}
				    />
				</div>}
				{!this.state.showList && <AgregarRoles togShowList={this.toggleShowList} openList={this.showList} selectedRole={this.state.selectedRole}
				></AgregarRoles>}
				<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toggleAlert} onAccept={this.toggleAlert}/>
				{this.state.openWheel && <Wheel/>}
			</div>

        );
    }
}

Roles.contextType = MainContext;
export default Roles;