import React, { Component } from 'react';
import './AgregarSalarios.css';
import axios from 'axios';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../../../../MainContext';
import Alert from '../../../../shared/dialog/alert/Alert';
import Constants from '../../../../Constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

class AgregarSalarios extends Component {

	constructor(props) {
        super(props);
        let fecha = new Date().toISOString().split('T')[0];
	    this.state = {
	        hasError: false,
	        mySalario: {
                salario: '',
                fecha: fecha
			},
			alertData: {
				title: "Error",
				message: "hubo un error",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false
	    }
	}

	toogleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	checkStyle = (value) => {
		return value ? "error-input" : '';
	}

  	onSubmit = async (values, {setSubmitting, resetForm}) => {
		console.log('callled', values);
        delete values['fechaMap'];
		delete values['tableData'];
		values.fechaMap = values.fecha.split('T')[0];
		if(this.state.mySalario.pkIdSalario) {
			console.log('goona update');
			this.props.updateSalario(values);
            this.props.togShowList();
		} else {
            values.fkIdUsuario = this.props.usuario;
			this.props.addSalario(values);
            this.props.togShowList();
		}
	}

	componentDidMount() {
		this.setState({
			mySalario: this.props.selectedSalario
		});
	}

    render() {
        const map = (date) => {
            return date.split('T')[0]
        }
        console.log(this.state.mySalario);
        const today = new Date().toISOString().split('T')[0];
        console.log('today', today);
        return (
            <div className="form-container">
				<div>
					<Formik
							enableReinitialize
							initialValues={this.state.mySalario}
							validationSchema={Yup.object().shape({
						        salario: Yup.number()
						            .required('Ingrese el Salario')
						    })}
							onSubmit={this.onSubmit}
						>
						{({
						values,
						touched,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting
						}) => (
							<form onSubmit={handleSubmit} validate="false">
								{this.state.mySalario.salario && <h3>Editar Salario:</h3>}
								{!this.state.mySalario.salario && <h3>Nuevo Salario:</h3>}
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.salario)}`} name="salario" onChange={handleChange} value={values.salario} label="Salario"/>
								</div>
                                <p className="invalid-feedback">{errors.salario}</p>
                                <div className="form-group">
									{/* <TextField className={`input-form ${this.checkStyle(errors.fecha)}`} name="fecha" onChange={handleChange} value={values.fecha} label="Fecha"/> */}
                                    <TextField
                                        id="date"
                                        label="Fecha"
                                        name="fecha"
                                        type="date"
                                        value={map(values.fecha)}
                                        onChange={handleChange}
                                        className={`input-form ${this.checkStyle(errors.fecha)}`}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{ max: today }}
                                    />
                                </div>
                                <p className="invalid-feedback">{errors.fecha}</p>
								<button disabled={isSubmitting} className="btn btn-cancel" onClick={this.props.togShowList}>
									Cancelar
								</button>
								<button type="submit" disabled={isSubmitting} className="btn btn-save">
									Guardar
								</button>
							</form>
						)}
						</Formik>
					</div>
					<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toogleAlert} onAccept={this.toogleAlert}/>
            </div>
        );
    }
}

AgregarSalarios.contextType = MainContext;
export default AgregarSalarios;