import React, { Component } from 'react';
import './AgregarCategoriaDistribucion.css';
import axios from 'axios';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../../../MainContext';
import Alert from '../../../shared/dialog/alert/Alert';
import Constants from '../../../Constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Wheel from '../../../shared/dialog/wheel/Wheel';

class AgregarCategoriaDistribucion extends Component {

	constructor(props) {
        super(props);
	    this.state = {
	        hasError: false,
	        categoria: {
				nombre: '',
				tipo: 1
			},
			alertData: {
				title: "Error",
				message: "hubo un error",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			openWheel: false
	    }
	}

	toogleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	checkStyle = (value) => {
		return value ? "error-input" : '';
	}

  	onSubmit = async (values, {setSubmitting, resetForm}) => {
		this.setState({
			openWheel: true
		});
        delete values['tableData'];
		if(this.state.categoria.pkIdCategoria) {
			axios.put(Constants.api+'/v1/categoria_distribucion/' + values.pkIdCategoria,values)
			.then(async resp => {
				this.setState({
					openWheel: false
				});
                this.props.togShowList();
			});
		} else {
			axios.post(Constants.api+'/v1/categoria_distribucion',values)
			.then(async (resp) => {
				this.setState({
					openWheel: false,
					categoria: {
                        nombre: values.nombre,
						pkIdCategoria: resp.data[0].insertId
					}
				});
                this.props.togShowList();
			});
		}
	}

	componentDidMount() {
		this.setState({
			categoria: this.props.selectedItem
		});
	}

    render() {
        return (
            <div className="form-container">
				<div>
					<Formik
							enableReinitialize
							initialValues={this.state.categoria}
							validationSchema={Yup.object().shape({
						        nombre: Yup.string()
						            .required('Ingrese el Nombre')
						    })}
							onSubmit={this.onSubmit}
						>
						{({
						values,
						touched,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting
						}) => (
							<form onSubmit={handleSubmit} validate="false">
								{this.state.categoria.nombre && <h3>Editar categoría de distribución:</h3>}
								{!this.state.categoria.nombre && <h3>Nueva categoría de distribución:</h3>}
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.nombre)}`} disabled={this.state.categoria.pkIdCategoria} name="nombre" onChange={handleChange} value={values.nombre} label="Nombre"/>
								</div>
                                <p className="invalid-feedback">{errors.nombre}</p>
								<FormControl className="tipo">
										<InputLabel id="demo-simple-select-label">Tipo</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											className={`input-form ${this.checkStyle(errors.tipo)}`}
											name="tipo"
											value={values.tipo}
											onChange={handleChange}
											>
												<MenuItem value='1'>Estandar</MenuItem>
												<MenuItem value='2'>Adicional</MenuItem>
										</Select>
									</FormControl>
								{values.tipo == 2 && <div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.unidad)}`} name="unidad" onChange={handleChange} value={values.unidad} label="Unidad"/>
								</div>}
								<button disabled={isSubmitting} className="btn btn-cancel" onClick={this.props.togShowList}>
									Cancelar
								</button>
								<button type="submit" disabled={isSubmitting} className="btn btn-save">
									Guardar
								</button>
							</form>
						)}
						</Formik>
					</div>
					<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toogleAlert} onAccept={this.toogleAlert}/>
					{this.state.openWheel && <Wheel/>}
            </div>
        );
    }
}

AgregarCategoriaDistribucion.contextType = MainContext;
export default AgregarCategoriaDistribucion;