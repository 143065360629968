import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div>
                <p>Bienvenido al Sistema Web de Constructora Izquierdo version 2.3</p>
            </div>
        );
    }
}

export default Home;