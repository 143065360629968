import React, { Component, forwardRef } from 'react';
import './Acabado.css';
import axios from 'axios';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/AddCircle';
import Check from '@material-ui/icons/Check';
import FilterList from '@material-ui/icons/FilterList';
import Remove from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MainContext from '../../MainContext';
import AgregarAcabado from './AgregarAcabado/AgregarAcabado';
import Constants from '../../Constants';
import Wheel from '../../shared/dialog/wheel/Wheel';
import Alert from '../../shared/dialog/alert/Alert';

class Acabado extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      	columns: [
			  { title: 'Nombre', field: 'nombre' },
			  { title: 'Precio', field: 'precio' },
			  { title: 'Categoria', field: 'categoria' },
			  { title: 'Ranking', field: 'ranking' }
		    ],
			data: [],
			categList: [],
		    items: [],
	    	showList: true,
	    	selectedItem: {
                nombre: ''
            },
			alertData: {
				title: "Error",
				message: "hubo un error",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			openWheel: false
	    }
	}

	toggleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	openEdit = (item)=> {
		delete item['precio'];
		const categList = this.state.data.filter(cat => cat.categoria === item.categoria);
        this.setState({
			selectedItem: item,
			categList: categList,
            showList: !this.state.showList
        });
  	}

    showList = ()=> {
    	this.reloadData();
  		this.setState({showList: true});
  	}

    toggleShowList = ()=> {
          this.setState({showList: !this.state.showList});
          this.reloadData();
  	}
  	openList = ()=> {
  		this.setState({
  			showList: false,
  			selectedItem: {
				nombre: '',
				imgUrl: '',
				versiones: []
            }
  		});
  	}

  	reloadData = () => {
		this.setState({
			openWheel: true
		});
  		axios.get(Constants.api + '/v1/acabado')
	    .then((resp)=>{
			resp.data = resp.data.sort((a,b) => (a.categoria+' '+a.ranking > b.categoria+' '+b.ranking) ? 1 : ((b.categoria+' '+b.ranking > a.categoria+' '+a.ranking) ? -1 : 0));
			let index = 1;
			let currCateg = ''
			const ac = resp.data.map(acab => {
				if (acab.categoria !== currCateg) {
					index = 1;
				}
				currCateg = acab.categoria;
				acab.precio = acab.versiones.length ? acab.versiones[acab.versiones.length - 1].precio : 0;
				acab.ranking = index;
				index++;
				return acab;
			});
		  	this.setState({ 
				data: ac,
				openWheel: false
		  	});
	    }).catch(error => {
			this.setState({
				openWheel: false
			});
	    	if (error.message.indexOf('401') >= 0) {
	    		this.context.logOut();
	    	}
        });
  	}

    componentDidMount(){
        this.reloadData();
    }
    
    render() {
        return (
        	<div>        	
	            {this.state.showList && <div className="table-container">
	            	{this.context.hasPermission('crear-rol') && <div className="add-role" onClick={this.openList}>
	            		<h3>Agregar acabados</h3>
	            		<Add />
	            	</div>}
	                <MaterialTable
				      title="Acabados"
				      localization={{
				        header: {
				            actions: 'Acciones'
				        },
				        body: {
				            emptyDataSourceMessage: 'No hay datos',				            
					        editRow: {
					          saveTooltip: "Aceptar",
					          cancelTooltip: "Cancelar",
					          deleteText: "Realmente desea eliminar este acabado?"
					        },
					        addTooltip: "Agregar nuevo acabado",
					        deleteTooltip: "Eliminar",
					        editTooltip: "Editar"
					      
				        },
				        toolbar: { searchPlaceholder: 'Buscar' }
				      }}
				      columns={this.state.columns}
                      data={this.state.data}
				      icons={{ 
			            Check: Check,
			            DetailPanel: ChevronRight,
			            Export: SaveAlt,
			            Filter: FilterList,
			            FirstPage: FirstPage,
			            LastPage: LastPage,
			            NextPage: ChevronRight,
			            PreviousPage: ChevronLeft,
			            Search: Search,
			            Delete: forwardRef((props, ref) => <Remove {...props} ref={ref} className="red-icon"/>),
			            Edit: Edit,
			            Add: Add,
			            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} className="red-icon"/>),
			            ResetSearch: Clear,
			            SortArrow: ArrowDownward
			          }}
				      editable={{
				        onRowDelete: (oldData) =>
				          new Promise((resolve) => {
                            axios.delete(Constants.api + '/v1/acabado/'+oldData.pkIdAcabado)
                            .then((resp)=>{		  							  	
                                resolve();
                                this.reloadData();
                            }).catch(error => {
								if (error.message.indexOf('401') >= 0) {
									this.context.logOut();
								} else {
									let alertData = this.state.alertData;
									alertData.message = "No se puede eliminar un acabado con datos asociados";
									this.setState({alertData: alertData});					  	
									  resolve();
									  this.toggleAlert();
								}
							});
				          }),
				      }}
				      actions={[
						  {
						    icon: Edit,
						    tooltip: 'Editar',
						    onClick: (event, rowData) => this.openEdit(rowData)
						  }
                        ]}
                        options={{
                            paging: true
                        }}
				    />
				</div>}
				{!this.state.showList && <AgregarAcabado togShowList={this.toggleShowList} openList={this.showList} selectedItem={this.state.selectedItem} categList={this.state.categList}
				acabados={this.state.data}></AgregarAcabado>}
				<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toggleAlert} onAccept={this.toggleAlert}/>
				{this.state.openWheel && <Wheel/>}
			</div>

        );
    }
}

Acabado.contextType = MainContext;
export default Acabado;