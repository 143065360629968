import React from 'react';
import {useDropzone} from 'react-dropzone';
import './UsuarioArchivos.css';

function UsuarioArchivos(props) {
    const onDrop = (files) => {
        props.seleccionarArchivo(files[0]);
    }

    const {getRootProps, getInputProps} = useDropzone({multiple: true, onDrop});

    return (
        <section className="container">
            {props.type}
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Arrastre el documento que desea subir</p>
            </div>
        </section>
    );
}

export default UsuarioArchivos;