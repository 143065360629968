import React, { Component, forwardRef } from 'react';
import { Link } from "react-router-dom";
import './Tareas.css';
import axios from 'axios';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/AddCircle';
import Check from '@material-ui/icons/Check';
import FilterList from '@material-ui/icons/FilterList';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MainContext from '../MainContext';
import Constants from '../Constants';

class Tareas extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      	columns: [
			  { title: 'Mensaje', field: 'mensaje' },
              { title: 'Link', field: 'link', render: rowData => <Link onClick={() => this.handleClickAlert(rowData)} to={rowData.link + '?alerta='+rowData.pkIdAlerta}>Visitar</Link>},
              { title: 'Revisada', field: 'rev'},
			  { title: 'Fecha', field: 'date'}
		    ],
		    data: [],
		    items: [],
	    	showList: true,
	    	selectedItem: {
                nombre: ''
            },
			alertData: {
				title: "Error",
				message: "hubo un error",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false
	    }
    }
    
    handleClickAlert = (a) => {
        if(a.revisada != 1){
            a.revisada = 1;
            delete a['date'];
            delete a['rev'];
            delete a['tableData'];
            axios.put(Constants.api + '/v1/alerta/' + a.pkIdAlerta, a)
            .then(res => {
                // getAlerts();
            })
        }
    }

	toggleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	openEdit = (item)=> {
        this.setState({
            selectedItem: item,
            showList: !this.state.showList
        });
  	}

    showList = ()=> {
    	this.reloadData();
  		this.setState({showList: true});
  	}

    toggleShowList = ()=> {
          this.setState({showList: !this.state.showList});
          this.reloadData();
  	}
  	openList = ()=> {
  		this.setState({
  			showList: false,
  			selectedItem: {
                nombre: ''
            }
  		});
  	}

  	reloadData = () => {
        axios.get(Constants.api + '/v1/alerta?usuario=' + this.context.myUser.pkIdUsuario)
	    .then((resp)=>{
            console.log(resp.data);
            const alerts = resp.data.map(a => {
                a.date = a.timestamp.split('T')[0];
                a.rev = (a.revisada === 1) ? 'Revisada': 'Sin Revisar';
                return a;
            });
            console.log(alerts);
		  	this.setState({ 
                data: alerts
		  	});
	    }).catch(error => {
	    	if (error.message.indexOf('401') >= 0) {
	    		this.context.logOut();
	    	}
        });
  	}

    componentDidMount(){
        if(this.context.myUser.pkIdUsuario){
            this.reloadData();
        } else {
            let interval = setInterval(()=>{
                if(this.context.myUser.pkIdUsuario){
                    clearInterval(interval);
                    this.reloadData();
                }
            }, 300);
        }
    }
    
    render() {
        return (
        	<div>        	
	            {this.state.showList && <div className="table-container">
	                <MaterialTable
				      title="Tareas"
				      localization={{
				        header: {
				        },
				        body: {
				            emptyDataSourceMessage: 'No hay datos',				            
					        editRow: {
					          saveTooltip: "Aceptar",
					          cancelTooltip: "Cancelar",
					          deleteText: "Realmente desea eliminar esta alerta?"
					        }
				        },
				        toolbar: { searchPlaceholder: 'Buscar' }
				      }}
				      columns={this.state.columns}
                      data={this.state.data}
				      icons={{ 
			            Check: Check,
			            DetailPanel: ChevronRight,
			            Export: SaveAlt,
			            Filter: FilterList,
			            FirstPage: FirstPage,
			            LastPage: LastPage,
			            NextPage: ChevronRight,
			            PreviousPage: ChevronLeft,
			            Search: Search,
			            Add: Add,
			            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} className="red-icon"/>),
			            ResetSearch: Clear,
			            SortArrow: ArrowDownward
			          }}
                        options={{
                            paging: true
                        }}
				    />
				</div>}
				{/* {!this.state.showList && <AgregarDistribucion togShowList={this.toggleShowList} openList={this.showList} selectedItem={this.state.selectedItem}
				></AgregarDistribucion>} */}
				{/* <Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toggleAlert} onAccept={this.toggleAlert}/> */}
			</div>

        );
    }
}

Tareas.contextType = MainContext;
export default Tareas;