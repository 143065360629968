import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  NavLink
} from "react-router-dom";
import MainContext from './MainContext';

export const mainListItems = (

  <MainContext.Consumer>
    {mainContext => (
      <div className="navigation">
        <NavLink to="/" exact activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </NavLink>
        <NavLink to="/tareas" exact activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Tareas" />
          </ListItem>
        </NavLink>
        {mainContext.hasPermission('crear-usuario') && <NavLink to="/usuarios" exact activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItem>
        </NavLink>}
        {(mainContext.hasPermission('crear-usuario') || mainContext.hasPermission('crear-cliente')) && <NavLink to="/clientes" exact activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItem>
        </NavLink>}
        {/* {mainContext.hasPermission('ver-rol') && <NavLink to="/roles" exact activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <LockOpenIcon />
            </ListItemIcon>
            <ListItemText primary="Roles" />
          </ListItem>
        </NavLink>} */}
        {mainContext.hasPermission('ver-proyecto') && <NavLink to="/proyectos" activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <HomeWorkIcon />
            </ListItemIcon>
            <ListItemText primary="Proyectos" />
          </ListItem>
        </NavLink>}
        {/* {mainContext.hasPermission('ver-reportes') && <NavLink to="/reportes" exact activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Reportes" />
          </ListItem>
        </NavLink>} */}
        {mainContext.hasPermission('administrar-info') && <NavLink to="/admin" activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
        </NavLink>}
      </div>
    )}
  </MainContext.Consumer>
);

export const secondaryListItems = (
  <div>
    {/* <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem> */}
  </div>

);
