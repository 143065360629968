import React, { Component, forwardRef } from 'react';
import './Salarios.css';
import axios from 'axios';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/AddCircle';
import Check from '@material-ui/icons/Check';
import FilterList from '@material-ui/icons/FilterList';
import Remove from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MainContext from '../../../MainContext';
import AgregarSalarios from './AgregarSalarios/AgregarSalarios';
// import Alert from '../shared/dialog/alert/Alert';

class SalariosLocal extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      	columns: [
              { title: 'salario', field: 'salario' },
              { title: 'fecha', field: 'fechaMap' }
		    ],
		    SalariosData: [],
		    salarios: [],
	    	showList: true,
	    	selectedSalario: {
                salario: '',
                fecha: new Date().toISOString().split('T')[0]
            },
			alertData: {
				title: "Error",
				message: "hubo un error",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false
	    }
	}

	toggleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	openEdit = (salario)=> {
        this.setState({
            selectedSalario: salario,
            showList: !this.state.showList
        });
  	}

    showList = ()=> {
  		this.setState({showList: true});
  	}

    toggleShowList = ()=> {
		this.setState({showList: !this.state.showList});
	}
	
	addSalario = (sal) => {
		sal.pkIdSalario = this.state.SalariosData.length + 1;
		this.setState({
			SalariosData: [...this.state.SalariosData, sal]
		})
		this.props.updateOnSalarios(this.state.SalariosData);
	}

	updateSalario = (sal) => {
		let sals = [...this.state.SalariosData];
		sals[sal.pkIdSalario - 1] = sal;
		this.setState({
			SalariosData: [...sals]
		});
		this.props.updateOnSalarios(this.state.SalariosData);
	}
	
  	openList = ()=> {
  		this.setState({
  			showList: false,
  			selectedSalario: {
                salario: '',
                fecha: new Date().toISOString().split('T')[0]
            }
  		});
  	}


    componentDidMount(){
    }
    
    render() {
        return (
        	<div>        	
	            {this.state.showList && <div className="table-container">
	            	{this.context.hasPermission('crear-rol') && <div className="add-role" onClick={this.openList}>
	            		<h3>Agregar Salario</h3>
	            		<Add />
	            	</div>}
	                <MaterialTable
				      title=""
				      localization={{
				        header: {
				            actions: 'Acciones'
				        },
				        body: {
				            emptyDataSourceMessage: 'No hay datos',				            
					        editRow: {
					          saveTooltip: "Aceptar",
					          cancelTooltip: "Cancelar",
					          deleteText: "Realmente desea eliminar este Rol?"
					        },
					        addTooltip: "Agregar nuevo Rol",
					        deleteTooltip: "Eliminar",
					        editTooltip: "Editar"
					      
				        },
				        toolbar: { searchPlaceholder: 'Buscar' }
				      }}
				      columns={this.state.columns}
                      data={this.state.SalariosData}
				      icons={{ 
			            Check: Check,
			            DetailPanel: ChevronRight,
			            Export: SaveAlt,
			            Filter: FilterList,
			            FirstPage: FirstPage,
			            LastPage: LastPage,
			            NextPage: ChevronRight,
			            PreviousPage: ChevronLeft,
			            Search: Search,
			            Delete: forwardRef((props, ref) => <Remove {...props} ref={ref} className="red-icon"/>),
			            Edit: Edit,
			            Add: Add,
			            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} className="red-icon"/>),
			            ResetSearch: Clear,
			            SortArrow: ArrowDownward
			          }}
				      editable={{
				        onRowDelete: (oldData) =>
				          new Promise((resolve) => {
							let sal = [...this.state.SalariosData];
							sal = sal.filter(sa => sa.pkIdSalario != oldData.pkIdSalario);
							this.setState({
								SalariosData: sal
							})
							resolve();
				          }),
				      }}
				      actions={[
						  {
						    icon: Edit,
						    tooltip: 'Editar',
						    onClick: (event, rowData) => this.openEdit(rowData)
						  }
                        ]}
                        components={{
                            Toolbar: props => (
                              <div>
                              </div>
                            ),
                          }}
                        options={{
                            paging: true
                        }}
				    />
				</div>}
				{!this.state.showList && <AgregarSalarios togShowList={this.toggleShowList} addSalario={this.addSalario} updateSalario={this.updateSalario} usuario={this.props.usuario} openList={this.showList} selectedSalario={this.state.selectedSalario}
				></AgregarSalarios>}
				{/* <Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toggleAlert} onAccept={this.toggleAlert}/> */}
			</div>

        );
    }
}

SalariosLocal.contextType = MainContext;
export default SalariosLocal;