import React, { Component } from 'react';
import './AgregarRoles.css';
import axios from 'axios';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../../MainContext';
import Alert from '../../shared/dialog/alert/Alert';
import Constants from '../../Constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Wheel from '../../shared/dialog/wheel/Wheel';

class AgregarRoles extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	        hasError: false,
	        myRole: {
				nombre: ''
			},
			permisos: [],
			rolePermisos: [],
			alertData: {
				title: "Error",
				message: "hubo un error",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			openWheel: false
	    }
	}

	handleCheckChieldElement = (event) => {
	    let permisos = this.state.permisos;
	    permisos.forEach(permiso => {
	       if (permiso.pkIdPermiso === parseInt(event.target.value))
	          permiso.checked = event.target.checked
	    });
	    this.setState({permisos: permisos});
	}

	toogleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	checkStyle = (value) => {
		return value ? "error-input" : '';
	}

  	onSubmit = async (values, {setSubmitting, resetForm}) => {
		this.setState({
			openWheel: true
		});
		if(this.state.myRole.pkIdRol) {
			delete values['tableData'];
			axios.put(Constants.api+'/v1/rol/' + values.pkIdRol,values)
			.then(async resp => {
				this.savePermisos();
			});
		} else {
			axios.post(Constants.api+'/v1/rol',values)
			.then(async (resp)=>{
				this.setState({
					myRole: {
						nombre: values.nombre,
						pkIdRol: resp.data[0].insertId
					}
				});
				this.savePermisos();
			});
		}
	}

	savePermisos = () => {
		let permisos = this.state.permisos;
	    for (var i = permisos.length - 1; i >= 0; i--) {
	        console.log(this.state.permisos[i],this.state.rolePermisos[i]);
	       	if (permisos[i].checked && (!this.state.rolePermisos.length || !this.state.rolePermisos[i].checked)) {
	          	axios.post(`${Constants.api}/v1/rol/${this.state.myRole.pkIdRol}/permiso`,{"fkIdRol": this.state.myRole.pkIdRol, "fkIdPermiso": permisos[i].pkIdPermiso})
			    .then((response)=>{	
					this.setState({
						openWheel: false
					});
					this.props.openList();
			    }).catch(error => {
			    	if (error.message.indexOf('401') >= 0) {
			    		this.context.logOut();
			    	}
		        });
	       	} else if (!permisos[i].checked && (!this.state.rolePermisos.length || this.state.rolePermisos[i].checked)) {
	          	axios.delete(`${Constants.api}/v1/rol/${this.state.myRole.pkIdRol}/permiso/${permisos[i].pkIdPermiso}`)
			    .then((response)=>{	
					this.setState({
						openWheel: false
					});
					this.props.openList();
			    }).catch(error => {
			    	if (error.message.indexOf('401') >= 0) {
			    		this.context.logOut();
			    	}
		        });
	       	} else {
				this.setState({
					openWheel: false
				});
				this.props.openList();
	       	}
	    };
	} 

	componentDidMount() {
		this.setState({
			myRole: this.props.selectedRole
		});
	        
		axios.get(Constants.api+'/v1/permiso')
		    .then((resp)=>{		    	
			  	this.setState({
			  		permisos: resp.data
			  	});
			  	axios.get(`${Constants.api}/v1/rol/${this.state.myRole.pkIdRol}/permiso`)
			    .then((response)=>{	
				  	let newPermisos = this.state.permisos.map((perm) => {
				  		perm.checked = response.data.some(permiso => permiso.fkIdPermiso === perm.pkIdPermiso);
				  		perm.rolPermiso = response.data.find(permiso => permiso.fkIdPermiso === perm.pkIdPermiso);
				  		return perm;
				  	});
				  	this.setState({
				  		rolePermisos: JSON.parse(JSON.stringify(newPermisos)),
						permisos: JSON.parse(JSON.stringify(newPermisos))
				  	});
			    }).catch(error => {
			    	if (error.message.indexOf('401') >= 0) {
			    		this.context.logOut();
			    	}
		        });
		    }).catch(error => {
		    	if (error.message.indexOf('401') >= 0) {
		    		this.context.logOut();
		    	}
            });
	}

    render() {
        return (
            <div className="form-container">
				<div>
					<Formik
							enableReinitialize
							initialValues={this.state.myRole}
							validationSchema={Yup.object().shape({
						        nombre: Yup.string()
						            .required('Ingrese el nombre')
						    })}
							onSubmit={this.onSubmit}
						>
						{({
						values,
						touched,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting
						}) => (
							<form onSubmit={handleSubmit} validate="false">
								{this.state.myRole.nombre && <h3>Editar Rol:</h3>}
								{!this.state.myRole.nombre && <h3>Nuevo Rol:</h3>}
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.nombre)}`} name="nombre" onChange={handleChange} value={values.nombre} label="Nombre"/>
								</div>
								<h3>Permisos:</h3>
							    {this.state.permisos.map(item => (
							 	<div className="form-group">
						            <label key={item.name} className="check-box">
						              	<input type="checkbox" name={item.nombre} checked={item.checked} value={item.pkIdPermiso} onClick={this.handleCheckChieldElement}/>
						              	{item.nombre}
											<span className="checkmark"></span>
						            </label>
							          
								</div>))}
								<p className="invalid-feedback">{errors.nombre}</p>
								<button disabled={isSubmitting} className="btn btn-cancel" onClick={this.props.togShowList}>
									Cancelar
								</button>
								<button type="submit" disabled={isSubmitting} className="btn btn-save">
									Guardar
								</button>
							</form>
						)}
						</Formik>
					</div>
					<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toogleAlert} onAccept={this.toogleAlert}/>
					{this.state.openWheel && <Wheel/>}
            </div>
        );
    }
}

AgregarRoles.contextType = MainContext;
export default AgregarRoles;