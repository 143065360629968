import React, { Component } from 'react';
import './Password.css';
import axios from 'axios';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../../../MainContext';
import Alert from '../../../shared/dialog/alert/Alert';
import Constants from '../../../Constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

class Password extends Component {

	constructor(props) {
        super(props);
	    this.state = {
	        hasError: false,
	        myPassword: {
                nueva: '',
                confirmacion: ''
			},
			myUser: {},
			alertData: {
				title: "Error",
				message: "Los valores ingresados no coinciden.",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false
	    }
	}

	toogleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	checkStyle = (value) => {
		return value ? "error-input" : '';
	}

  	onSubmit = async (values, {setSubmitting, resetForm}) => {
		console.log('called', values);
		if(values.nueva === values.confirmacion) {
			if(values.nueva.length >= 6) {
				console.log('goona update');
				this.props.usuario.clave = values.confirmacion;
				let userEdit = JSON.parse(JSON.stringify(this.props.usuario));
				delete userEdit['salarios'];
				delete userEdit['permisos'];
				axios.put(Constants.api+'/v1/usuario/' + this.props.usuario.pkIdUsuario, userEdit)
				.then(async resp => {
					this.props.onClose();
				});
			} else {
	            this.toogleAlert();
			}
		} else {
            this.toogleAlert();
		}
	}

	componentDidMount() {
		this.setState({
			myUser: this.props.usuario
		});
	}

    render() {
        return (
            <div className="form-container">
				<div>
					<Formik
							enableReinitialize
							initialValues={this.state.myPassword}
							validateOnChange={false}
							validationSchema={Yup.object().shape({
						        nueva: Yup.string().min(6, 'Debe tener al menos 6 dígitos')
						            .required('Digite una contraseña'),
						        confirmacion: Yup.string().min(6, 'Debe tener 6 dígitos')
						            .required('Digite una confirmación')
						    })}
							onSubmit={this.onSubmit}
						>
						{({
						values,
						touched,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting
						}) => (
							<form onSubmit={handleSubmit} validate="false">
								<p>La contraseña debe tener un mínimo de 6 dígitos</p>
								<div className="form-group">
									<TextField type="password" className={`input-form ${this.checkStyle(errors.nueva)}`} name="nueva" onChange={handleChange} value={values.nueva} label="Nueva Contraseña"/>
									<p className="invalid-feedback add-form">{errors.nueva}</p>
								</div>
								<div className="form-group">
									<TextField type="password" className={`input-form ${this.checkStyle(errors.confirmacion)}`} name="confirmacion" onChange={handleChange} value={values.confirmacion} label="Confirme Contraseña"/>
									<p className="invalid-feedback add-form">{errors.confirmacion}</p>
								</div>
                                <p className="invalid-feedback">{errors.fecha}</p>
								<button disabled={isSubmitting} className="btn btn-cancel" onClick={this.props.onClose}>
									Cancelar
								</button>
								<button type="submit" disabled={isSubmitting} className="btn btn-save">
									Guardar
								</button>
							</form>
						)}
						</Formik>
					</div>
					<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toogleAlert} onAccept={this.toogleAlert}/>
            </div>
        );
    }
}

Password.contextType = MainContext;
export default Password;