import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
    NavLink
} from "react-router-dom";


import MainContext from '../MainContext';

import './Admin.css'

const useStyles = makeStyles({
  root: {
    width: 275,
    marginRight: 20,
    border: '1px solid black',
    marginBottom: 18
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Admin = () => {
    const classes = useStyles();
    
    return (
        <MainContext.Consumer>
            {mainContext => (
                <div className="cards">
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Acabados
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <NavLink to="/admin/acabado" exact activeClassName="active">
                                <Button size="small">Administrar</Button>
                            </NavLink>
                        </CardActions>
                    </Card>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Distribuciones
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <NavLink to="/admin/distribucion" exact activeClassName="active">
                                <Button size="small">Administrar</Button>
                            </NavLink>
                        </CardActions>
                    </Card>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Categorias de Acabados
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <NavLink to="/admin/categoria_acabado" exact activeClassName="active">
                                <Button size="small">Administrar</Button>
                            </NavLink>
                        </CardActions>
                    </Card>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Categorias de Distribución
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <NavLink to="/admin/categoria_distribucion" exact activeClassName="active">
                                <Button size="small">Administrar</Button>
                            </NavLink>
                        </CardActions>
                    </Card>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Precios Base
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <NavLink to="/admin/precios_base" exact activeClassName="active">
                                <Button size="small">Administrar</Button>
                            </NavLink>
                        </CardActions>
                    </Card>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Promociones
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <NavLink to="/admin/promociones" exact activeClassName="active">
                                <Button size="small">Administrar</Button>
                            </NavLink>
                        </CardActions>
                    </Card>
                    {/* <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Sistemas Constructivos
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <NavLink to="/sistema_constructivo" exact activeClassName="active">
                                <Button size="small">Administrar</Button>
                            </NavLink>
                        </CardActions>
                    </Card> */}
                    {mainContext.hasPermission('ver-rol') && <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Roles
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <NavLink to="/admin/roles" exact activeClassName="active">
                                <Button size="small">Administrar</Button>
                            </NavLink>
                        </CardActions>
                    </Card>}
                </div>
            )}
        </MainContext.Consumer>
    );
}

export default Admin;